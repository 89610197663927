import React from 'react';
import SEO from '../components/seo';
import Reveal from '../components/Reveal';
import Carousel from '../components/Carousel';
import ComponentList from '../components/ComponentList';
import PageRichText from '../components/PageRichText';
import SiteArtists from '../components/SiteArtists';
import NewsletterPopup from '../components/NewsletterPopup';
import ArrowRight from '../components/icons/ArrowRight';

import cx from 'classnames';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import sanityImage from '../util/sanityImage';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import useHover from '../util/useHover';

const SitePost = ({ pageContext, location }) => {
	const [sliderRef, slider] = useKeenSlider({
		slidesPerView: 1,
		loop: true,
		spacing: 50,
	});

	const [hoverRef, isHovered] = useHover();

	const {
		seo = {},
		title,
		subtitle,
		hero,
		heroGallery = [],
		intro,
		locationCover,
		locationDescription = [],
		installationImages = [],
		artistsIntroText,
		artists = [],
		inquire,
		footer,
	} = pageContext;

	const metaTitle = seo?.metaTitle || 'SITE | Library Street Collective';
	const openGraphTitle =
		seo?.openGraphTitle || 'SITE | Library Street Collective';
	const twitterTitle =
		seo?.twitterTitle || 'SITE | Library Street Collective';

	const heroSection = (heroGallery || hero) && (
		<Reveal>
			<section className="reveal__slide reveal__delay--1">
				{heroGallery && heroGallery.length > 0 ? (
					<div className="grid-container contained mt5 mt10--md mb5 mb10--md mb20--lg pr">
						<div
							ref={sliderRef}
							className="project__carousel keen-slider df pr"
						>
							{heroGallery.map(image => (
								<picture
									key={image._key}
									className="keen-slider__slide"
								>
									<source
										srcSet={sanityImage(image.url, {
											w: 2400,
										})}
										media="(min-width: 1800px)"
									/>
									<source
										srcSet={sanityImage(image.url, {
											w: 2000,
										})}
										media="(min-width: 1600px)"
									/>
									<source
										srcSet={sanityImage(image.url, {
											w: 1600,
										})}
										media="(min-width: 1200px)"
									/>
									<source
										srcSet={sanityImage(image.url, {
											w: 1200,
										})}
										media="(min-width: 800px)"
									/>
									<source
										srcSet={sanityImage(image.url, {
											w: 1000,
										})}
										media="(min-width: 600px)"
									/>
									<img
										alt={
											image.alt ||
											'SITE | Library Street Collective'
										}
										className="db x y object-fit--cover b--black"
										src={sanityImage(image.url, {
											w: 800,
										})}
									/>
								</picture>
							))}
						</div>
						{slider && (
							<React.Fragment>
								<button
									className="slider__button slider__button--prev pa"
									onClick={e =>
										e.stopPropagation() || slider.prev()
									}
								>
									<ArrowRight className="icon icon--vertical icon--slider flip" />
								</button>
								<button
									className="slider__button slider__button--next pa"
									onClick={e =>
										e.stopPropagation() || slider.next()
									}
								>
									<ArrowRight className="icon icon--vertical icon--slider" />
								</button>
							</React.Fragment>
						)}
					</div>
				) : (
					<div className="artwork--full-container bb--black">
						<picture ref={hoverRef}>
							<source
								srcSet={sanityImage(hero.url, { w: 2400 })}
								media="(min-width: 1800px)"
							/>
							<source
								srcSet={sanityImage(hero.url, { w: 2000 })}
								media="(min-width: 1600px)"
							/>
							<source
								srcSet={sanityImage(hero.url, { w: 1600 })}
								media="(min-width: 1200px)"
							/>
							<source
								srcSet={sanityImage(hero.url, { w: 1200 })}
								media="(min-width: 800px)"
							/>
							<source
								srcSet={sanityImage(hero.url, { w: 1000 })}
								media="(min-width: 600px)"
							/>
							<img
								alt={
									hero.alt ||
									'SITE | Library Street Collective'
								}
								className={cx('db x y artwork--full', {
									hovered: isHovered,
								})}
								src={sanityImage(hero.url, { w: 800 })}
							/>
						</picture>
					</div>
				)}
				<aside className="mt2 grid-container contained">
					<h1 className="sans--24 sans--36--md sans--48--lg">
						SITE: {title}
					</h1>

					<p className="sans--14 sans--18--md sans--24--lg">
						{subtitle
							? subtitle
							: 'Art and Architecture in the Digital Space'}
					</p>
				</aside>
			</section>
		</Reveal>
	);

	const introSection = intro && (
		<Reveal>
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mt20--lg mb5 mb10--md mb20--lg grid-container contained">
				<div className="row align--center">
					<div className="col c10--md c9--lg c7--xl">
						<div className="sans--18 sans--24--md line-break rich-text ">
							<BlockContent
								blocks={intro}
								serializers={richText}
							/>
						</div>
					</div>
				</div>
			</div>
		</Reveal>
	);

	const locationCoverSection = locationCover && (
		<Reveal>
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mt20--lg mb5 mb10--md mb20--lg grid-container contained">
				<picture>
					<source
						srcSet={`${locationCover.url}?w=2000&auto=format&q=100`}
						media="(min-width: 1200px)"
					/>
					<source
						srcSet={`${locationCover.url}?w=1200&auto=format&q=100`}
						media="(min-width: 1000px)"
					/>
					<source
						srcSet={`${locationCover.url}?w=800&auto=format`}
						media="(min-width: 600px)"
					/>
					<img
						alt={
							locationCover.alt || 'Image courtesy of the artist'
						}
						className="db x b--black"
						src={`${locationCover.url}?w=600&auto=format`}
					/>
				</picture>
				<p className="mt2 sans--24 sans--36--md sans--48--lg">
					{title}
				</p>
			</div>
		</Reveal>
	);

	const locationDescriptionSection = locationDescription && (
		<Reveal>
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mt20--lg mb5 mb10--md mb20--lg grid-container contained">
				<ComponentList components={locationDescription} />
			</div>
		</Reveal>
	);

	const installationSection = installationImages && (
		<Reveal>
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mt20--lg mb5 mb10--md mb20--lg ">
				<p className="sans--24 sans--36--md sans--48--lg grid-container contained mb2">
					Installation Images
				</p>

				<Carousel list={installationImages} />
			</div>
		</Reveal>
	);

	const mailtoLink =
		inquire &&
		`mailto:info@lscgallery.com?subject=${inquire?.subject}&body= ${inquire?.body}`;

	const artistsSection = artists && artists.length > 0 && (
		<Reveal>
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mt20--lg mb5 mb10--md mb20--lg grid-container contained">
				{artistsIntroText && (
					<PageRichText content={artistsIntroText} />
				)}
				<SiteArtists artists={artists} />
			</div>
		</Reveal>
	);

	const inquireSection = inquire && (
		<div className="mt5 mb5 mt10--md mb10--md mt20--lg mb20--lg grid-container contained">
			<a href={mailtoLink} className=" tc x db tile--large">
				<span className="sans--24 sans--36--md sans--48--lg">
					{(inquire && inquire.linkText) ||
						`Inquire about available works in ${title}`}
				</span>
			</a>
		</div>
	);

	return (
		<React.Fragment>
			<SEO
				metaTitle={metaTitle}
				metaDescription={seo?.metaDescription}
				metaKeywords={seo?.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo?.openGraphDescription}
				openGraphImage={seo?.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo?.twitterDescription}
				twitterImage={seo?.twitterImage}
				pathname={location.pathname}
			/>
			<NewsletterPopup type="SITE" title={title} {...footer} />
			{heroSection}
			{introSection}
			{locationCoverSection}
			{locationDescriptionSection}
			{installationSection}
			{artistsSection}
			{inquireSection}
		</React.Fragment>
	);
};

export default SitePost;
