import React, { useContext } from 'react';
import SEO from '../components/seo';
import Reveal from '../components/Reveal';
import Link from '../components/Link';
import VideoPlayer from '../components/VideoPlayer';
import Context from '../context/Context';
import { ArrowRight } from '../components/SVG';

import cx from 'classnames';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import sanityImage from '../util/sanityImage';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import useHover from '../util/useHover';
import dateHelper from '../util/dateHelper';

const Project = ({ pageContext, location }) => {
	const [hoverRef, isHovered] = useHover();

	const [sliderRef, slider] = useKeenSlider({
		slidesPerView: 1,
		loop: true,
		spacing: 50,
	});

	const { setLightBoxOpen, setFeaturedImage } = useContext(Context);

	const {
		seo = {},
		title,
		artist,
		hero,
		heroGallery = [],
		description,
		video,
		projectGalleryTitle,
		projectGallery = [],
		recommendedProjectsTitle,
		recommendedProjects = [],
	} = pageContext;

	const metaTitle = seo?.metaTitle || 'Library Street Collective';
	const openGraphTitle = seo?.openGraphTitle || 'Library Street Collective';
	const twitterTitle = seo?.twitterTitle || 'Library Street Collective';

	const heroSection = (heroGallery || hero) && (
		<Reveal
			className={cx('', {
				'mb5 mb10--md mb20--lg': heroGallery && heroGallery.length > 0,
			})}
		>
			<section className="reveal__slide reveal__delay--1">
				{heroGallery && heroGallery.length > 0 ? (
					<div className="grid-container contained mt5 mt10--md pr">
						<div
							ref={sliderRef}
							className="project__carousel keen-slider df pr"
						>
							{heroGallery.map(image => (
								<picture
									key={image._key}
									className="keen-slider__slide"
								>
									<source
										srcSet={sanityImage(image.url, {
											w: 2400,
										})}
										media="(min-width: 1800px)"
									/>
									<source
										srcSet={sanityImage(image.url, {
											w: 2000,
										})}
										media="(min-width: 1600px)"
									/>
									<source
										srcSet={sanityImage(image.url, {
											w: 1600,
										})}
										media="(min-width: 1200px)"
									/>
									<source
										srcSet={sanityImage(image.url, {
											w: 1200,
										})}
										media="(min-width: 800px)"
									/>
									<source
										srcSet={sanityImage(image.url, {
											w: 1000,
										})}
										media="(min-width: 600px)"
									/>
									<img
										alt={
											image.alt ||
											title ||
											artist ||
											'Library Street Collective'
										}
										className="db x y object-fit--cover b--black"
										src={sanityImage(image.url, {
											w: 800,
										})}
									/>
								</picture>
							))}
						</div>
						{slider && (
							<React.Fragment>
								<button
									className="slider__button slider__button--prev pa"
									onClick={e =>
										e.stopPropagation() || slider.prev()
									}
								>
									<ArrowRight className="icon icon--vertical icon--slider flip" />
								</button>
								<button
									className="slider__button slider__button--next pa"
									onClick={e =>
										e.stopPropagation() || slider.next()
									}
								>
									<ArrowRight className="icon icon--vertical icon--slider" />
								</button>
							</React.Fragment>
						)}
					</div>
				) : (
					<picture
						ref={hoverRef}
						className="db x bb--black artwork--full-container"
					>
						<source
							srcSet={sanityImage(hero.url, { w: 2400 })}
							media="(min-width: 1800px)"
						/>
						<source
							srcSet={sanityImage(hero.url, { w: 2000 })}
							media="(min-width: 1600px)"
						/>
						<source
							srcSet={sanityImage(hero.url, { w: 1600 })}
							media="(min-width: 1200px)"
						/>
						<source
							srcSet={sanityImage(hero.url, { w: 1200 })}
							media="(min-width: 800px)"
						/>
						<source
							srcSet={sanityImage(hero.url, { w: 1000 })}
							media="(min-width: 600px)"
						/>
						<img
							alt={
								hero.alt ||
								title ||
								artist ||
								'Library Street Collective'
							}
							className={cx('db artwork--full', {
								hovered: isHovered,
							})}
							src={sanityImage(hero.url, { w: 800 })}
						/>
					</picture>
				)}
				<aside className="mt2 grid-container contained">
					<h1 className="sans--24 sans--36--md sans--48--lg">
						{artist}
					</h1>

					<p className="sans--18 sans--24--md">{title}</p>
				</aside>
			</section>
		</Reveal>
	);

	const introDescription = description && (
		<Reveal>
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg grid-container contained">
				<div className="row align--center">
					<div className="col c10--md c9--lg c7--xl">
						<div className="sans--18 sans--24--md line-break rich-text ">
							<BlockContent
								blocks={description}
								serializers={richText}
							/>
						</div>
					</div>
				</div>
			</div>
		</Reveal>
	);

	const introVideo = video && (
		<Reveal>
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg grid-container contained">
				<VideoPlayer {...video} />
			</div>
		</Reveal>
	);

	const projectGallerySection = projectGallery && projectGallery.length > 0 && (
		<Reveal>
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg">
				<div className="grid-container contained">
					<p className="sans--24 sans--36--md sans--48--lg mb5 mb10--md">
						{projectGalleryTitle || 'Project Gallery'}
					</p>

					<section className="row df fw">
						{projectGallery.map((item, index) => (
							<article
								key={item._key}
								className={cx('col c6--md', {
									'mt2 mt0--md': index > 0,
									'mt3--md mt5--lg': index > 1,
								})}
							>
								<picture>
									<source
										srcSet={sanityImage(item.artwork.url, {
											w: 2000,
										})}
										media="(min-width: 1600px)"
									/>
									<source
										srcSet={sanityImage(item.artwork.url, {
											w: 1600,
										})}
										media="(min-width: 1200px)"
									/>
									<source
										srcSet={sanityImage(item.artwork.url, {
											w: 1200,
										})}
										media="(min-width: 800px)"
									/>
									<source
										srcSet={sanityImage(item.artwork.url, {
											w: 1000,
										})}
										media="(min-width: 600px)"
									/>
									<img
										alt={
											item.title ||
											title ||
											artist ||
											'Library Street Collective'
										}
										className="db x b--black pointer"
										src={sanityImage(item.artwork.url, {
											w: 800,
										})}
										onClick={() => {
											setFeaturedImage(item);
											setTimeout(() => {
												setLightBoxOpen(true);
											}, 100);
										}}
									/>
								</picture>
								{item.title && (
									<p className="mt2 sans--14 sans--18--lg">
										{item.title}
									</p>
								)}
								{item.description && (
									<div className="mt2 sans--14 sans--18--lg">
										<BlockContent
											blocks={item.description}
											serializers={richText}
										/>
									</div>
								)}
							</article>
						))}
					</section>
				</div>
			</div>
		</Reveal>
	);

	const recommendedProjectsSection = recommendedProjects &&
		recommendedProjects.length > 0 && (
			<Reveal>
				<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg grid-container contained">
					<p className="sans--24 sans--36--md sans--48--lg mb5 mb10--md">
						{recommendedProjectsTitle || 'Related Projects'}
					</p>
					<section className="row df fw">
						{recommendedProjects.map((project, index) => {
							const {
								_id,
								linkType,
								slug,
								title,
								subtitle,
								artist,
								heroImage,
								hero,
								image,
								startDate,
								endDate,
							} = project;

							const picture = heroImage || image || hero;

							let destination;

							switch (linkType) {
								case 'exhibition':
									destination = `/exhibitions/${slug}`;
									break;
								case 'artist':
									destination = `/artists/${slug}`;
									break;
								case 'site':
									destination = `/exhibitions/site-${slug}`;
									break;
								case 'anatomy':
									destination = `/exhibitions/anatomy-${slug}`;
									break;
								case 'queue':
									destination = `/exhibitions/queue-${slug}`;
									break;
								case 'alliance':
									destination = `/alliance/${slug}`;
									break;
								case 'project':
									destination = `/projects/${slug}`;
									break;
								case 'page':
									destination = `/${pageSlug}`;
									break;

								default:
									break;
							}
							return (
								<Link
									key={_id}
									url={destination}
									className={cx('col c4--md', {
										'mt2 mt0--md': index > 0,
										'mt3--md mt5--lg': index > 2,
									})}
								>
									<picture className="db b--black image--tile x">
										<source
											srcSet={sanityImage(picture.url, {
												w: 1200,
											})}
											media="(min-width: 600px)"
										/>
										<img
											alt={
												title ||
												artist ||
												'Library Street Collective'
											}
											className="db featured-link--image y"
											src={sanityImage(picture.url, {
												w: 1200,
											})}
										/>
									</picture>

									<aside className="mt2">
										<p className="mt2 sans--18 sans--24--md">
											{linkType === 'alliance' &&
												`Alliance: ${title}`}
											{linkType === 'anatomy' &&
												`Anatomy: ${title}`}
											{linkType === 'site' &&
												`SITE: ${title}`}
											{linkType === 'artist' && artist}
											{linkType === 'exhibition' &&
												artist}
											{linkType === 'project' && artist}
										</p>
										{(linkType === 'project' ||
											linkType === 'exhibition') && (
											<p className="sans--14 sans--18--md">
												{title}
											</p>
										)}
										{subtitle && (
											<p className="sans--14 sans--18--md">
												{subtitle}
											</p>
										)}
										{endDate && startDate && (
											<p className="sans--14 sans--18--lg">
												{dateHelper(startDate)} —{' '}
												{dateHelper(endDate)}
											</p>
										)}
									</aside>
								</Link>
							);
						})}
					</section>
				</div>
			</Reveal>
		);

	return (
		<React.Fragment>
			<SEO
				metaTitle={metaTitle}
				metaDescription={seo?.metaDescription}
				metaKeywords={seo?.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo?.openGraphDescription}
				openGraphImage={seo?.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo?.twitterDescription}
				twitterImage={seo?.twitterImage}
				pathname={location.pathname}
			/>
			{heroSection}
			{introDescription}
			{introVideo}
			{projectGallerySection}
			{recommendedProjectsSection}
		</React.Fragment>
	);
};

export default Project;
