import scrollTo from 'magic-tricks/lib/scrollTo';

const getDistanceFromTop = el => {
	var location = 0;

	if (el.offsetParent) {
		do {
			location += el.offsetTop;
			el = el.offsetParent;
		} while (el);
	}

	return location >= 0 ? location : 0;
};

const scroll = id => {
	const elemHash = document.getElementById(id);

	if (!elemHash) return;

	setTimeout(() => {
		scrollTo(getDistanceFromTop(elemHash) - 90);
	}, 750);
};

export default scroll;
