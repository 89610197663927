import React, { Component } from 'react';
import sanity from '@sanity/client';
import * as queries from '../api/queries';
import queryString from 'query-string';

// Routes
import Home from './Home';
import AboutPage from './AboutPage';
import ExhibitionsIndex from './ExhibitionsIndex';
import Exhibition from './Exhibition';
import Site from './Site';
import Anatomy from './Anatomy';
import ArtistList from './ArtistList';
import Artist from './Artist';
import AllianceIndex from './AllianceIndex'
import Alliance from './Alliance'
import Project from './Project';
import ProjectList from './ProjectList';
import Page from './Page';
import NewsPage from './NewsPage';
import Product from './Product';
import Shop from './Shop';

const client = sanity({
	projectId: process.env.GATSBY_SANITY_PROJECT_ID,
	dataset: process.env.GATSBY_SANITY_DATASET,
	token: process.env.GASTBY_SANITY_PREVIEW_API_TOKEN,
	useCdn: false,
	withCredentials: true,
});

const INITIAL_STATE = {
	draft: null,
	queryResult: null,
};

export default class Preview extends Component {
	constructor() {
		super();
		this.state = { ...INITIAL_STATE };
	}

	componentDidMount = () => {
		const params = queryString.parse(this.props.location.search, {
			arrayFormat: 'comma',
		});

		if (!params.documentId) return this.throwError();

		client
			.fetch(queries.draftDocument(params.documentId))
			.then(draft => {
				this.setState({ draft });

				switch (draft._type) {
					case 'homepage':
						return client
							.fetch(queries.draftHomepage(draft._id))
							.then(homepage => {
								this.setState({ queryResult: homepage });
							})
							.catch(error => {
								console.log(error);
								this.throwError(error);
							});
					case 'aboutpage':
						return client
							.fetch(queries.draftAboutpage(draft._id))
							.then(aboutpage => {
								this.setState({ queryResult: aboutpage });
							})
							.catch(error => {
								console.log(error);
								this.throwError(error);
							});
					case 'exhibitionpage':
						return client
							.fetch(queries.draftExhibitionsPage(draft._id))
							.then(exhibitionsIndex => {
								this.setState({
									queryResult: exhibitionsIndex,
								});
							})
							.catch(error => {
								console.log(error);
								this.throwError(error);
							});
					case 'exhibition':
						return client
							.fetch(queries.draftExhibition(draft._id))
							.then(exhibition => {
								this.setState({ queryResult: exhibition });
							})
							.catch(error => {
								console.log(error);
								this.throwError(error);
							});
					case 'site':
						return client
							.fetch(queries.draftSite(draft._id))
							.then(site => {
								this.setState({ queryResult: site });
							})
							.catch(error => {
								console.log(error);
								this.throwError(error);
							});
					case 'anatomy':
						return client
							.fetch(queries.draftAnatomy(draft._id))
							.then(anatomy => {
								this.setState({ queryResult: anatomy });
							})
							.catch(error => {
								console.log(error);
								this.throwError(error);
							});
					case 'queue':
						return client
							.fetch(queries.draftQueue(draft._id))
							.then(queue => {
								this.setState({ queryResult: queue });
							})
							.catch(error => {
								console.log(error);
								this.throwError(error);
							});
					case 'alliance':
						return client
							.fetch(queries.draftAlliance(draft._id))
							.then(alliance => {
								this.setState({ queryResult: alliance });
							})
							.catch(error => {
								console.log(error);
								this.throwError(error);
							});
					case 'alliancepage':
						return client
							.fetch(queries.draftAlliancepage(draft._id))
							.then(alliancePage => {
								this.setState({ queryResult: alliancePage });
							})
							.catch(error => {
								console.log(error);
								this.throwError(error);
							});
					case 'project':
						return client
							.fetch(queries.draftProject(draft._id))
							.then(projectpage => {
								this.setState({ queryResult: projectpage });
							})
							.catch(error => {
								console.log(error);
								this.throwError(error);
							});
					case 'projectspage':
						return client
							.fetch(queries.draftProjectspage(draft._id))
							.then(projectList => {
								this.setState({ queryResult: projectList });
							})
							.catch(error => {
								console.log(error);
								this.throwError(error);
							});
					case 'artistspage':
						return client
							.fetch(queries.draftArtistsPage(draft._id))
							.then(artistsIndex => {
								this.setState({ queryResult: artistsIndex });
							})
							.catch(error => {
								console.log(error);
								this.throwError(error);
							});
					case 'artist':
						return client
							.fetch(queries.draftArtist(draft._id))
							.then(artistpage => {
								this.setState({ queryResult: artistpage });
							})
							.catch(error => {
								console.log(error);
								this.throwError(error);
							});
					case 'newspage':
						return client
							.fetch(queries.draftNewsPage(draft._id))
							.then(newsPage => {
								this.setState({ queryResult: newsPage });
							})
							.catch(error => {
								console.log(error);
								this.throwError(error);
							});
					case 'page':
						return client
							.fetch(queries.draftPage(draft._id))
							.then(page => {
								this.setState({ queryResult: page });
							})
							.catch(error => {
								console.log(error);
								this.throwError(error);
							});
					case 'product':
						return client
							.fetch(queries.draftProduct(draft._id))
							.then(product => {
								this.setState({ queryResult: product });
							})
							.catch(error => {
								console.log(error);
								this.throwError(error);
							});
					case 'productspage':
						return client
							.fetch(queries.draftShopPage(draft._id))
							.then(shopPage => {
								this.setState({ queryResult: shopPage });
							})
							.catch(error => {
								console.log(error);
								this.throwError(error);
							});
					default:
						return client
							.fetch(queries.draftHomepage(draft._id))
							.then(homepage => {
								this.setState({ queryResult: homepage });
							})
							.catch(error => {
								console.log(error);
								this.throwError(error);
							});
						break;
				}
			})
			.catch(error => {
				console.log(error);
				this.throwError();
			});
	};

	throwError = error => {
		alert(error);
	};

	render() {
		const { queryResult, draft } = this.state;
		const { pageContext, pathContext, ...props } = this.props;

		if (!queryResult || !draft) {
			return <div className="pt10 pb10 tc">Loading Preview…</div>;
		}

		switch (draft._type) {
			case 'homepage':
				return (
					<Home
						{...props}
						pageContext={{
							...pageContext,
							...queryResult,
						}}
						pathContext={{
							...pathContext,
							...queryResult,
						}}
					/>
				);
			case 'aboutpage':
				return (
					<AboutPage
						{...props}
						pageContext={{
							...pageContext,
							...queryResult,
						}}
						pathContext={{
							...pathContext,
							...queryResult,
						}}
					/>
				);
			case 'exhibitionpage':
				return (
					<ExhibitionsIndex
						{...props}
						pageContext={{
							...pageContext,
							...queryResult,
						}}
						pathContext={{
							...pathContext,
							...queryResult,
						}}
					/>
				);
			case 'exhibition':
				return (
					<Exhibition
						{...props}
						pageContext={{
							...pageContext,
							...queryResult,
						}}
						pathContext={{
							...pathContext,
							...queryResult,
						}}
					/>
				);
			case 'anatomy':
				return (
					<Anatomy
						{...props}
						pageContext={{
							...pageContext,
							...queryResult,
						}}
						pathContext={{
							...pathContext,
							...queryResult,
						}}
					/>
				);
			case 'queue':
				return (
					<Anatomy //TODO: Update
						{...props}
						pageContext={{
							...pageContext,
							...queryResult,
						}}
						pathContext={{
							...pathContext,
							...queryResult,
						}}
					/>
				);
			case 'site':
				return (
					<Site
						{...props}
						pageContext={{
							...pageContext,
							...queryResult,
						}}
						pathContext={{
							...pathContext,
							...queryResult,
						}}
					/>
				);
			case 'alliance':
				return (
					<Alliance
						{...props}
						pageContext={{
							...pageContext,
							...queryResult,
						}}
						pathContext={{
							...pathContext,
							...queryResult,
						}}
					/>
				);
			case 'alliancepage':
				return (
					<AllianceIndex
						{...props}
						pageContext={{
							...pageContext,
							...queryResult,
						}}
						pathContext={{
							...pathContext,
							...queryResult,
						}}
					/>
				);
			case 'project':
				return (
					<Project
						{...props}
						pageContext={{
							...pageContext,
							...queryResult,
						}}
						pathContext={{
							...pathContext,
							...queryResult,
						}}
					/>
				);
			case 'projectspage':
				return (
					<ProjectList
						{...props}
						pageContext={{
							...pageContext,
							...queryResult,
						}}
						pathContext={{
							...pathContext,
							...queryResult,
						}}
					/>
				);
			case 'artistspage':
				return (
					<ArtistList
						{...props}
						pageContext={{
							...pageContext,
							...queryResult,
						}}
						pathContext={{
							...pathContext,
							...queryResult,
						}}
					/>
				);
			case 'artist':
				return (
					<Artist
						{...props}
						pageContext={{
							...pageContext,
							...queryResult,
						}}
						pathContext={{
							...pathContext,
							...queryResult,
						}}
					/>
				);
			case 'newspage':
				return (
					<NewsPage
						{...props}
						pageContext={{
							...pageContext,
							...queryResult,
						}}
						pathContext={{
							...pathContext,
							...queryResult,
						}}
					/>
				);
			case 'page':
				return (
					<Page
						{...props}
						pageContext={{
							...pageContext,
							...queryResult,
						}}
						pathContext={{
							...pathContext,
							...queryResult,
						}}
					/>
				);
			case 'product':
				return (
					<Product
						{...props}
						pageContext={{
							...pageContext,
							...queryResult,
						}}
						pathContext={{
							...pathContext,
							...queryResult,
						}}
					/>
				);
			case 'productspage':
				return (
					<Shop
						{...props}
						pageContext={{
							...pageContext,
							...queryResult,
						}}
						pathContext={{
							...pathContext,
							...queryResult,
						}}
					/>
				);
			default:
				return (
					<Home
						{...props}
						pageContext={{
							...pageContext,
							...queryResult,
						}}
						pathContext={{
							...pathContext,
							...queryResult,
						}}
					/>
				);
		}
	}
}
