import React from 'react';
import Reveal from '../components/Reveal';
import SEO from '../components/seo';
import { ArrowRight } from '../components/SVG';
import Link from '../components/Link';

import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import cx from 'classnames'
import sanityImage from '../util/sanityImage';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import useHover from '../util/useHover'

const AboutPage = ({ pageContext, location }) => {
  const {
		seo = {},
		gallery = [],
		description,
		contacts = [],
		info,
  } = pageContext;


  const metaTitle = seo?.metaTitle || 'About | Library Street Collective';
  const openGraphTitle =
		seo?.openGraphTitle || 'About | Library Street Collective';
  const twitterTitle = seo?.twitterTitle || 'About | Library Street Collective';

  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 1,
    loop: true,
    spacing: 50,
  });

  const [hoverRef, isHovered] = useHover();


  return (
		<React.Fragment>
			<SEO
				metaTitle={metaTitle}
				metaDescription={seo?.metaDescription}
				metaKeywords={seo?.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo?.openGraphDescription}
				openGraphImage={seo?.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo?.twitterDescription}
				twitterImage={seo?.twitterImage}
				pathname={location.pathname}
			/>
			<Reveal>
				<section className="reveal__slide reveal__delay--1 mb5 mb10--md mb20--lg pr">
					{gallery.length > 1 ? (
						<React.Fragment>
							<div className="grid-container contained mt5 mt10--md">
								<aside
									ref={sliderRef}
									className="keen-slider df pr"
								>
									{gallery.map(image => (
										<picture
											key={image._key}
											className="keen-slider__slide"
										>
											<source
												srcSet={sanityImage(image.url, {
													w: 2400,
												})}
												media="(min-width: 1800px)"
											/>
											<source
												srcSet={sanityImage(image.url, {
													w: 2000,
												})}
												media="(min-width: 1600px)"
											/>
											<source
												srcSet={sanityImage(image.url, {
													w: 1600,
												})}
												media="(min-width: 1200px)"
											/>
											<source
												srcSet={sanityImage(image.url, {
													w: 1200,
												})}
												media="(min-width: 800px)"
											/>
											<source
												srcSet={sanityImage(image.url, {
													w: 1000,
												})}
												media="(min-width: 600px)"
											/>
											<img
												alt={
													image.alt ||
													'Library Street Collective'
												}
												className="db x y object-fit--cover b--black"
												src={sanityImage(image.url, {
													w: 800,
												})}
											/>
										</picture>
									))}
								</aside>
								{slider && (
									<React.Fragment>
										<button
											className="slider__button slider__button--prev pa"
											onClick={e =>
												e.stopPropagation() ||
												slider.prev()
											}
										>
											<ArrowRight className="icon icon--vertical icon--slider flip" />
										</button>
										<button
											className="slider__button slider__button--next pa"
											onClick={e =>
												e.stopPropagation() ||
												slider.next()
											}
										>
											<ArrowRight className="icon icon--vertical icon--slider" />
										</button>
									</React.Fragment>
								)}
							</div>
						</React.Fragment>
					) : (
						<div className="artwork--full-container bb--black">
							<picture ref={hoverRef}>
								<source
									srcSet={sanityImage(gallery[0].url, {
										w: 2400,
									})}
									media="(min-width: 1800px)"
								/>
								<source
									srcSet={sanityImage(gallery[0].url, {
										w: 2000,
									})}
									media="(min-width: 1600px)"
								/>
								<source
									srcSet={sanityImage(gallery[0].url, {
										w: 1600,
									})}
									media="(min-width: 1200px)"
								/>
								<source
									srcSet={sanityImage(gallery[0].url, {
										w: 1200,
									})}
									media="(min-width: 800px)"
								/>
								<source
									srcSet={sanityImage(gallery[0].url, {
										w: 1000,
									})}
									media="(min-width: 600px)"
								/>
								<img
									alt={
										gallery[0].alt ||
										'Library Street Collective'
									}
									className={cx('db x y artwork--full', {
										hovered: isHovered,
									})}
									src={sanityImage(gallery[0].url, {
										w: 800,
									})}
								/>
							</picture>
						</div>
					)}
				</section>
			</Reveal>

			<Reveal>
				<section className="reveal__slide reveal__delay--1 grid-container contained mt5 mt10--md mb5 mb10--md mb20--lg">
					<h1 className="hidden-title">
						About Library Street Collective
					</h1>
					<div className="row">
						<div className="col c10--md">
							<div className="sans--24 sans--36--lg rich-text">
								<BlockContent
									blocks={description}
									serializers={richText}
								/>
							</div>
						</div>
						<section className="mt5 mt10--md mt20--lg col ">
							<div className="row">
								<div className="col c5--md">
									{info && (
										<div className="sans--18 sans--24--md rich-text line-break">
											<BlockContent
												blocks={info}
												serializers={richText}
											/>
										</div>
									)}
								</div>
								<div className="col c7--md mt10 mt0--lg">
									<ul className="row df fw">
										{contacts.map((contact, index) => (
											<li
												key={contact._key}
												className={cx('col c6', {
													'mt2 mt5--md': index > 1,
												})}
											>
												<p className="sans--18 sans--24--md mb1">
													{contact.name}
												</p>
												{contact.position && (
													<p className="sans--14 sans--18--md">
														{contact.position}
													</p>
												)}
												{contact.email && (
													<a
														href={`mailto:${contact.email}`}
														className="db link--underline-reverse fit sans--14 sans--18--md"
													>
														{contact.email}
													</a>
												)}
											</li>
										))}
									</ul>
								</div>
							</div>
						</section>
					</div>
				</section>
			</Reveal>
		</React.Fragment>
  );
};

export default AboutPage;
