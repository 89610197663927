import React from 'react';
import SEO from '../components/seo';
import Reveal from '../components/Reveal';
import ComponentList from '../components/ComponentList';
import Link from '../components/Link';

const IndexPage = ({ pageContext, location }) => {
  const { seo = {}, components = [] } = pageContext;

  const metaTitle = seo?.metaTitle || 'Library Street Collective';
  const openGraphTitle = seo?.openGraphTitle || 'Library Street Collective';
  const twitterTitle = seo?.twitterTitle || 'Library Street Collective';

  return (
		<React.Fragment>
			<SEO
				metaTitle={metaTitle}
				metaDescription={seo?.metaDescription}
				metaKeywords={seo?.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo?.openGraphDescription}
				openGraphImage={seo?.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo?.twitterDescription}
				twitterImage={seo?.twitterImage}
				pathname={location?.pathname}
			/>
			<h1 className="hidden-title test-code ">Library Street Collective</h1>
			<Reveal>
				<section className="reveal__slide reveal__delay--1">
					<ComponentList components={components} />					
				</section>
			</Reveal>
		</React.Fragment>
  );
};

export default IndexPage;
