import React from 'react';
import SEO from '../components/seo';
import Reveal from '../components/Reveal';
import Link from '../components/Link';

import centsToPrice from 'magic-tricks/lib/centsToPrice';
import cx from 'classnames';
import sanityImage from '../util/sanityImage';

const Shop = ({ pageContext, location }) => {
	const { seo = {}, title, list = [] } = pageContext;

	const metaTitle = seo?.metaTitle || 'Shop | Library Street Collective';
	const openGraphTitle =
		seo?.openGraphTitle || 'Shop | Library Street Collective';
	const twitterTitle =
		seo?.twitterTitle || 'Shop | Library Street Collective';

	return (
		<React.Fragment>
			<SEO
				metaTitle={metaTitle}
				metaDescription={seo?.metaDescription}
				metaKeywords={seo?.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo?.openGraphDescription}
				openGraphImage={seo?.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo?.twitterDescription}
				twitterImage={seo?.twitterImage}
				pathname={location.pathname}
			/>
			<Reveal>
				<section className="reveal__slide reveal__delay--1 grid-container contained mt5 mt10--md mb5 mb10--md mb20--lg pr">
					<h1 className="sans--24 sans--48--md sans--64--lg">
						{title || 'Shop Publications'}
					</h1>
					<ul className="row mt5 mt7--md mt10--lg">
						{list.map((item, index) => {
							const {
								_key,
								slug,
								title,
								vendor,
								thumbnail,
								cents,
							} = item;
							return (
								<li
									key={_key}
									className={cx('col c6--md c4--lg', {
										mt5: index > 0,
										'mt0--md': index === 1,
										'mt0--lg': index === 2,
										'mt10--lg': index > 2,
									})}
								>
									<Link url={`/products/${slug}`}>
										<picture>
											<source
												srcSet={sanityImage(
													thumbnail.url,
													{
														w: 1200,
													}
												)}
												media="(min-width: 600px)"
											/>
											<img
												alt={
													title ||
													'Library Street Collective'
												}
												className="db x b--black"
												src={sanityImage(
													thumbnail.url,
													{
														w: 1200,
													}
												)}
											/>
										</picture>
										<div className="mt2 df jcb">
											<p className="sans--18 sans--24--md">
												{title}
												{vendor && (
													<React.Fragment>
														<span className="db sans--14 sans--18--md">
															{vendor}
														</span>
													</React.Fragment>
												)}
											</p>
											<p className="sans--14 sans--18--md">
												{centsToPrice(cents)}
											</p>
										</div>
									</Link>
								</li>
							);
						})}
					</ul>
				</section>
			</Reveal>
			<Reveal>
				<section className="reveal__slide reveal__delay--1 grid-container contained mt5 mt10--md mb5 mb10--md mb20--lg">
					<div className="row align--center">
						<div className="col c8--md c6--lg">
							<a
								href="https://www.louisbuhl.com/"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src="/images/louis-buhl-logo.png"
									className="db x col c6 mxa"
									alt="Louis / Buhl Logo"
								/>
								<p className="sans--14 sans--18--lg mt2 mt5--lg tc">
									For more, visit Louis Buhl & Co.
								</p>
							</a>
						</div>
					</div>
				</section>
			</Reveal>
		</React.Fragment>
	);
};

export default Shop;
