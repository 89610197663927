import React from 'react';
import Reveal from './Reveal';
import SiteArtistsNav from './SiteArtistsNav';
import SiteArtistSection from './SiteArtistSection';

export default ({ artists = [] }) => {
	return (
		<Reveal>
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg">
				<SiteArtistsNav list={artists} />

				{artists.map(artist => (
					<SiteArtistSection key={artist._key} {...artist} />
				))}
			</div>
		</Reveal>
	);
};
