import React from 'react';
import SEO from '../components/seo';
import Reveal from '../components/Reveal';
import { Link } from 'gatsby';
import { ArrowRight } from '../components/SVG';
import PullQuote from '../components/PullQuote'
import ComponentList from '../components/ComponentList';
import ArtworkSingle from '../components/ArtworkSingle'

import cx from 'classnames';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import sanityImage from '../util/sanityImage';
import useHover from '../util/useHover'
import scroll from '../util/scrollHelper'
import dateHelper from '../util/dateHelper';

const Artist = ({ pageContext, location }) => {
	const [hoverRef, isHovered] = useHover();
	const [sliderRef, slider] = useKeenSlider({
		slidesPerView: 1,
		loop: true,
		spacing: 50,
	});

	const {
		seo = {},
		title,
		hero,
		heroGallery = [],
		bio = {},
		selectedWork = [],
		featuredExhibitionsTitle,
		exhibitions = [],
		pressTitle,
		press = [],
		inquire = {},
	} = pageContext;

	const metaTitle = seo?.metaTitle || 'Library Street Collective';
	const openGraphTitle = seo?.openGraphTitle || 'Library Street Collective';
	const twitterTitle = seo?.twitterTitle || 'Library Street Collective';

	const artistNav = (
		<Reveal className="psy artist__nav  bg--white bb--black z10">
			<nav className="reveal__slide reveal__delay--1 grid-container contained pt1 pb1">
				<a
					className="mr1 mr2--lg"
					onClick={e => {
						e.preventDefault();
						scroll('artistBio');
					}}
					title="Link to artist's bio"
					href="#artistBio"
				>
					Bio
				</a>
				<a
					className="mr1 mr2--lg"
					onClick={e => {
						e.preventDefault();
						scroll('artistWork');
					}}
					title="Link to artist's works"
					href="#artistWork"
				>
					Work
				</a>
				{exhibitions && exhibitions.length > 0 && (
					<a
						className="mr1 mr2--lg"
						onClick={e => {
							e.preventDefault();
							scroll('artistExhibitions');
						}}
						title="Link to artist's exhibitions"
						href="#artistExhibitions"
					>
						Exhibitions
					</a>
				)}
				{press && press.length > 0 && (
					<a
						className="mr1 mr2--lg"
						onClick={e => {
							e.preventDefault();
							scroll('artistNews');
						}}
						href="#artistNews"
						title="Link to artist's news"
					>
						News
					</a>
				)}
				<a
					className="mr1 mr2--lg"
					onClick={e => {
						e.preventDefault();
						scroll('artistContact');
					}}
					title="Inquire artist's work"
					href="#artistContact"
				>
					Contact
				</a>
			</nav>
		</Reveal>
	);

	const heroSection = (heroGallery || hero) && (
		<Reveal>
			<section className="reveal__slide reveal__delay--1">
				{heroGallery && heroGallery.length > 0 ? (
					<div className="grid-container contained mt5 mt10--md pr">
						<div
							ref={sliderRef}
							className="project__carousel keen-slider df pr"
						>
							{heroGallery.map(image => (
								<picture
									key={image._key}
									className="keen-slider__slide"
								>
									<source
										srcSet={sanityImage(image.url, {
											w: 2400,
										})}
										media="(min-width: 1800px)"
									/>
									<source
										srcSet={sanityImage(image.url, {
											w: 2000,
										})}
										media="(min-width: 1600px)"
									/>
									<source
										srcSet={sanityImage(image.url, {
											w: 1600,
										})}
										media="(min-width: 1200px)"
									/>
									<source
										srcSet={sanityImage(image.url, {
											w: 1200,
										})}
										media="(min-width: 800px)"
									/>
									<source
										srcSet={sanityImage(image.url, {
											w: 1000,
										})}
										media="(min-width: 600px)"
									/>
									<img
										alt={
											image.alt ||
											title ||
											'Library Street Collective'
										}
										className="db x y object-fit--cover b--black"
										src={sanityImage(image.url, {
											w: 800,
										})}
									/>
								</picture>
							))}
						</div>
						{slider && (
							<React.Fragment>
								<button
									className="slider__button slider__button--prev pa"
									onClick={e =>
										e.stopPropagation() || slider.prev()
									}
								>
									<ArrowRight className="icon icon--vertical icon--slider flip" />
								</button>
								<button
									className="slider__button slider__button--next pa"
									onClick={e =>
										e.stopPropagation() || slider.next()
									}
								>
									<ArrowRight className="icon icon--vertical icon--slider" />
								</button>
							</React.Fragment>
						)}
					</div>
				) : (
					<picture
						ref={hoverRef}
						className="db x bb--black artwork--full-container"
					>
						<source
							srcSet={sanityImage(hero.url, { w: 2400 })}
							media="(min-width: 1800px)"
						/>
						<source
							srcSet={sanityImage(hero.url, { w: 2000 })}
							media="(min-width: 1600px)"
						/>
						<source
							srcSet={sanityImage(hero.url, { w: 1600 })}
							media="(min-width: 1200px)"
						/>
						<source
							srcSet={sanityImage(hero.url, { w: 1200 })}
							media="(min-width: 800px)"
						/>
						<source
							srcSet={sanityImage(hero.url, { w: 1000 })}
							media="(min-width: 600px)"
						/>
						<img
							alt={
								hero.alt || title || 'Library Street Collective'
							}
							className={cx('db artwork--full', {
								hovered: isHovered,
							})}
							src={sanityImage(hero.url, { w: 800 })}
						/>
					</picture>
				)}
				<aside className="mt2 grid-container contained">
					<h1 className="sans--24 sans--36--md sans--48--lg">
						{title}
					</h1>
				</aside>
			</section>
		</Reveal>
	);

	const bioSection = bio && (
		<Reveal>
			<div
				id="artistBio"
				className="reveal__slide reveal__delay--1 mt5 mt10--md mt20--lg mb5 mb10--md mb20--lg"
			>
				<div className="grid-container contained">
					<div className="row align--center">
						<div className="col c10--md c9--lg c7--xl">
							<div className="sans--18 sans--24--md line-break rich-text ">
								<BlockContent
									blocks={bio.bioIntro}
									serializers={richText}
								/>
							</div>
						</div>
						{bio.bioCV && (
							<div className="col c6--md">
								<a
									href={bio.bioCV.url}
									target="_blank"
									className="tile--large mt5 tc mxa db"
								>
									<span className="sans--18 sans--24--lg">
										Download {title}'s CV
									</span>
								</a>
							</div>
						)}
					</div>
					{bio.bioArtwork && <ArtworkSingle {...bio.bioArtwork} />}
				</div>
				{bio.bioQuote && <PullQuote {...bio.bioQuote} />}
			</div>
		</Reveal>
	);

	const selectedWorkSection = selectedWork && selectedWork.length > 0 && (
		<Reveal>
			<div
				id="artistWork"
				className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg"
			>
				<div className="grid-container contained">
					<p className="sans--24 sans--36--md sans--48--lg mb5 mb10--md">
						Selected Work
					</p>

					<ComponentList components={selectedWork} />
				</div>
			</div>
		</Reveal>
	);

	const exhibitionsSection = exhibitions && exhibitions.length > 0 && (
		<Reveal>
			<div
				id="artistExhibitions"
				className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg"
			>
				<div className="grid-container contained">
					<p className="sans--24 sans--36--md sans--48--lg mb5 mb10--md">
						{featuredExhibitionsTitle || 'Exhibitions'}
					</p>
					<ul className="row df fw">
						{exhibitions.map((exhibit, index) => {
							const {
								_type,
								_id,
								title,
								artist,
								subtitle,
								slug,
								image,
								hero,
								heroImage,
								startDate,
								endDate,
								linkType,
							} = exhibit;
							const picture = image || hero || heroImage;

							let destination;

							switch (linkType) {
								case 'exhibition':
									destination = `/exhibitions/${slug}`;
									break;
								case 'artist':
									destination = `/artists/${slug}`;
									break;
								case 'site':
									destination = `/exhibitions/site-${slug}`;
									break;
								case 'anatomy':
									destination = `/exhibitions/anatomy-${slug}`;
									break;
								case 'queue':
									destination = `/exhibitions/queue-${slug}`;
									break;
								case 'alliance':
									destination = `/alliance/${slug}`;
									break;
								case 'project':
									destination = `/projects/${slug}`;
									break;
								case 'page':
									destination = `/${pageSlug}`;
									break;
								default:
									destination = `${slug}`;
									break;
							}
							return (
								<li
									key={_id}
									className={cx('col c6--md c4--lg ', {
										mt5: index > 0,
										'mt0--md': index < 3,
										'mt10--md': index > 2,
									})}
								>
									<Link to={destination}>
										{picture && (
											<picture className="db b--black image--tile x">
												<source
													srcSet={`${picture.url}?w=1200&auto=format&q=100`}
													media="(min-width: 1000px)"
												/>
												<source
													srcSet={`${picture.url}?w=800&auto=format`}
													media="(min-width: 600px)"
												/>
												<img
													alt={`${artist}: ${title}`}
													className="db featured-link--image y"
													src={`${picture.url}?w=600&auto=format`}
												/>
											</picture>
										)}
										<p className="mt2 sans--18 sans--24--md">
											{_type === 'site' &&
												`SITE: ${title}`}
											{_type === 'anatomy' &&
												`Anatomy: ${title}`}
											{_type === 'artist' && artist}
											{_type === 'project' && artist}
											{_type === 'exhibition' && artist}
										</p>
										{(_type === 'exhibition' ||
											_type === 'project') && (
											<p className="sans--14 sans--18--md">
												{title}
											</p>
										)}
										{subtitle && (
											<p className="sans--14 sans--18--md">
												{subtitle}
											</p>
										)}
										{endDate && startDate && (
											<span className="sans--14 sans--18--md">
												{`${dateHelper(startDate)} —
													${dateHelper(endDate)}`}
											</span>
										)}
									</Link>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</Reveal>
	);

	const pressSection = press && press.length > 0 && (
		<Reveal>
			<div
				id="artistNews"
				className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg"
			>
				<div className="grid-container contained">
					<p className="sans--24 sans--36--md sans--48--lg mb5 mb10--md">
						{pressTitle || 'Press'}
					</p>

					<ul className="news__list mt2 mt5--md mb2 mb5--md row df fw">
						{press.map((article, index) => {
							return (
								<a
									key={article._key}
									href={article.url}
									target="_blank"
									rel="noreferrer noopener"
									className={cx('col c6--md c4--lg df fdc ', {
										mt5: index > 0,
										'mt0--md': index < 3,
										'mt10--md': index > 2,
									})}
								>
									<picture className="db b--black image--tile x">
										<source
											srcSet={`${article.thumbnail.url}?w=1200&auto=format&q=100`}
											media="(min-width: 1000px)"
										/>
										<source
											srcSet={`${article.thumbnail.url}?w=800&auto=format`}
											media="(min-width: 600px)"
										/>
										<img
											alt={article.publication}
											className="db featured-link--image y"
											src={`${article.thumbnail.url}?w=600&auto=format`}
										/>
									</picture>
									<p className="mt2 sans--12 sans--14--md">
										{article.year}
									</p>
									<p className="mt1 sans--18 sans--24--md ">
										{article.title}
									</p>
									<p className="sans--14 sans--18--md mt1">
										{article.publication}
									</p>
								</a>
							);
						})}
					</ul>
				</div>
			</div>
		</Reveal>
	);

	const mailtoLink =
		inquire &&
		`mailto:info@lscgallery.com?subject=${inquire?.subject}&body=${
			!!inquire.body ? inquire.body : ''
		}`;

	const inquireSection = inquire && (
		<div
			id="artistContact"
			className="mt5 mb5 mt10--md mb10--md mt20--lg mb20--lg grid-container contained"
		>
			<a href={mailtoLink} className=" tc x db tile--large">
				<span className="sans--24 sans--36--md sans--48--lg">
					{(inquire && inquire.linkText) ||
						`Inquire about available works by ${title}`}
				</span>
			</a>
		</div>
	);

	return (
		<React.Fragment>
			<SEO
				metaTitle={metaTitle}
				metaDescription={seo?.metaDescription}
				metaKeywords={seo?.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo?.openGraphDescription}
				openGraphImage={seo?.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo?.twitterDescription}
				twitterImage={seo?.twitterImage}
				pathname={location.pathname}
			/>
			{artistNav}
			{heroSection}
			{bioSection}
			{selectedWorkSection}
			{exhibitionsSection}
			{pressSection}
			{inquireSection}
		</React.Fragment>
	);
};

export default Artist;
