import React, { useState } from 'react';
import Reveal from '../components/Reveal';
import SEO from '../components/seo';

import cx from 'classnames';
import dateHelper from '../util/dateHelper';
import InfiniteScrollComponent from '../components/InfiniteScrollComponent';

const NewsPage = ({ pageContext, location }) => {
	const { seo = {}, title, list = [] } = pageContext;

	const metaTitle = seo?.metaTitle || 'News | Library Street Collective';
	const openGraphTitle =
		seo?.openGraphTitle || 'News | Library Street Collective';
	const twitterTitle =
		seo?.twitterTitle || 'News | Library Street Collective';

	return (
		<React.Fragment>
			<SEO
				metaTitle={metaTitle}
				metaDescription={seo?.metaDescription}
				metaKeywords={seo?.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo?.openGraphDescription}
				openGraphImage={seo?.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo?.twitterDescription}
				twitterImage={seo?.twitterImage}
				pathname={location.pathname}
			/>
			<Reveal className="grid-container contained mt5 mt10--md mb5 mb10--md mb20--lg">
				<section className="reveal__slide reveal__delay--1">
					<h1 className="sans--24 sans--48--md sans--64--lg">
						{title || 'News'}
					</h1>

					<InfiniteScrollComponent className="news__list mt2 mt5--md mb2 mb5--md row df fw">
						{list.map((article, index) => {
							return (
								<a
									key={article._key}
									href={article.url}
									target="_blank"
									rel="noreferrer noopener"
									className={cx('col c6--md c4--lg df fdc ', {
										mt5: index > 0,
										'mt0--md': index < 2,
										'mt0--lg': index < 3,
										'mt10--md': index > 2,
									})}
								>
									<picture className="db b--black image--tile x">
										<source
											srcSet={`${article.thumbnail}?w=800&auto=format`}
											media="(min-width: 600px)"
										/>
										<img
											alt={
												article.headline ||
												'Library Street Collective'
											}
											src={article.thumbnail}
											className="db featured-link--image y"
										/>
									</picture>
									<p className="mt2 sans--12 sans--14--md">
										{dateHelper(article.date)}
									</p>
									<p className="mt1 sans--18 sans--24--md ">
										{article.headline}
									</p>
									<p className="mt1 sans--14 sans--18--md">
										{article.source}
									</p>
								</a>
							);
						})}
					</InfiniteScrollComponent>
				</section>
			</Reveal>
		</React.Fragment>
	);
};

export default NewsPage;
