import React from 'react';
import SEO from '../components/seo';
import Reveal from '../components/Reveal';
import ComponentList from '../components/ComponentList';

import useHover from '../util/useHover';
import cx from 'classnames';

const Page = ({ pageContext, location }) => {
	const { seo = {}, title, image, components = [] } = pageContext;

	const metaTitle = seo?.metaTitle || 'Library Street Collective';
	const openGraphTitle = seo?.openGraphTitle || 'Library Street Collective';
	const twitterTitle = seo?.twitterTitle || 'Library Street Collective';
	const currentPath = location.pathname.replace(/^\//, '');

	const [hoverRef, isHovered] = useHover();

	return (
		<React.Fragment>
			<SEO
				metaTitle={metaTitle}
				metaDescription={seo?.metaDescription}
				metaKeywords={seo?.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo?.openGraphDescription}
				openGraphImage={seo?.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo?.twitterDescription}
				twitterImage={seo?.twitterImage}
				pathname={location.pathname}
			/>
			<Reveal>
				<main className="reveal__slide reveal__delay--1 mb5 mb10--md mb20--lg ">
					<section className="reveal__slide reveal__delay--1">
						{image && (
							<picture
								ref={hoverRef}
								className="db x bb--black artwork--full-container"
							>
								<source
									srcSet={`${image.url}?w=2000&auto=format&q=100`}
									media="(min-width: 1200px)"
								/>
								<source
									srcSet={`${image.url}?w=1200&auto=format&q=100`}
									media="(min-width: 1000px)"
								/>
								<source
									srcSet={`${image.url}?w=800&auto=format`}
									media="(min-width: 600px)"
								/>
								<img
									alt={
										image.alt ||
										title ||
										'Library Street Collective'
									}
									className={cx('db artwork--full', {
										hovered: isHovered,
									})}
									src={`${image.url}?w=600&auto=format`}
								/>
							</picture>
						)}
						<h1
							className={cx(
								'grid-container contained sans--24 sans--36--md sans--48--lg',
								{
									'mt5 mt10--md ': !image,
									'mt2 ': !!image,
								}
							)}
						>
							<span className={currentPath}>{title}</span>
						</h1>
					</section>
					<ComponentList components={components} />
				</main>
			</Reveal>
		</React.Fragment>
	);
};

export default Page;
