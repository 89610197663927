import React from 'react';
import SEO from '../components/seo';
import Reveal from '../components/Reveal';


import dateHelper from '../util/dateHelper';
import cx from 'classnames';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import { Link } from 'gatsby';

const AllianceIndex = ({ pageContext, location }) => {
	const {
		seo = {},
		featuredAlliance,
		description,
		howItWorks = [],
		pastAlliance = [],
	} = pageContext;

	const metaTitle = seo?.metaTitle || 'Alliance | Library Street Collective';
	const openGraphTitle =
		seo?.openGraphTitle || 'Alliance | Library Street Collective';
	const twitterTitle =
		seo?.twitterTitle || 'Alliance | Library Street Collective';

	const heroSection = (
		<Reveal>
			<section className="reveal__slide reveal__delay--1 mt5 mt10--lg mb5 mb10--md mb20--lg grid-container contained">
				<h1 className="sans--24 sans--36--md sans--48--lg">Alliance</h1>
				<div className="row align--center mt5 mt10--lg">
					<div className="col c10--md c9--lg c7--xl">
						<div className="sans--18 sans--24--md line-break rich-text ">
							<BlockContent
								blocks={description}
								serializers={richText}
							/>
						</div>
					</div>
				</div>
			</section>
		</Reveal>
	);

	const featuredAllianceSection = featuredAlliance && (
		<Reveal>
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg">
				<div className="grid-container contained">
					<p className="sans--24 sans--36--md sans--48--lg mb5 mb10--md">
						Featured Alliance
					</p>
					<Link to={`/alliance/${featuredAlliance.slug}`}>
						<picture>
							<source
								srcSet={`${featuredAlliance.heroImage.url}?w=2000&auto=format&q=100`}
								media="(min-width: 1200px)"
							/>
							<source
								srcSet={`${featuredAlliance.heroImage.url}?w=1200&auto=format&q=100`}
								media="(min-width: 1000px)"
							/>
							<source
								srcSet={`${featuredAlliance.heroImage.url}?w=800&auto=format`}
								media="(min-width: 600px)"
							/>
							<img
								alt={`${featuredAlliance.title} — ${featuredAlliance.artist}`}
								className="db x b--black"
								src={`${featuredAlliance.heroImage.url}?w=600&auto=format`}
							/>
						</picture>
						<div className="mt2 ">
							<p className="sans--24 sans--36--md">
								{featuredAlliance.artist}
							</p>
							<p className="sans--14 sans--18--md">
								{dateHelper(featuredAlliance.startDate)} — 
								{dateHelper(featuredAlliance.endDate)}
							</p>
						</div>
					</Link>
				</div>
			</div>
		</Reveal>
	);

	const instructionsSection = (
		<Reveal>
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg">
				<div className="grid-container contained">
					<p className="sans--24 sans--36--md sans--48--lg mb5 mb10--md">
						How it Works
					</p>
					<ul className="">
						{howItWorks.map((step, index) => (
							<li
								key={step + index}
								className={cx(
									'sans--18 sans--24--md sans--36--lg df',
									{
										mt1: index > 0,
									}
								)}
							>
								<span className=" mr1 mr2--lg">
									{index < 9 ? `0${index + 1}` : index + 1}
								</span>
								{step}
							</li>
						))}
					</ul>
				</div>
			</div>
		</Reveal>
	);

	const pastAlliancesSection = pastAlliance && pastAlliance.length > 0 && (
		<Reveal>
			<div className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg grid-container contained">
				<p className="sans--24 sans--36--md sans--48--lg mb5 mb10--md">
					Past Alliances
				</p>
				<div className="row">
					{pastAlliance.map((item, index) => {
						return (
							<article
								key={item._key}
								className={cx('col c6--lg', {
									mt5: index > 0,
									'mt0--md': index === 1,
									'mt10--md': index > 1,
								})}
							>
								<Link to={`/alliance/${item.slug}`}>
									<picture>
										<source
											srcSet={`${item.heroImage.url}?w=1000&auto=format&q=100`}
											media="(min-width: 800px)"
										/>
										<source
											srcSet={`${item.heroImage.url}?w=800&auto=format`}
											media="(min-width: 600px)"
										/>
										<img
											alt={
												item.title ||
												'Library Street Collective'
											}
											className="db x b--black"
											src={`${item.heroImage.url}?w=600&auto=format`}
										/>
									</picture>
									<div className="mt2 ">
										<p className="sans--18 sans--24--md">
											{item.artist}
										</p>
										<p className="sans--14 sans--18--md">
											{dateHelper(item.startDate)} — 
											{dateHelper(item.endDate)}
										</p>
									</div>
								</Link>
							</article>
						);
					})}
				</div>
			</div>
		</Reveal>
	);

	return (
		<React.Fragment>
			<SEO
				metaTitle={metaTitle}
				metaDescription={seo?.metaDescription}
				metaKeywords={seo?.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo?.openGraphDescription}
				openGraphImage={seo?.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo?.twitterDescription}
				twitterImage={seo?.twitterImage}
				pathname={location.pathname}
			/>
			{heroSection}
			{featuredAllianceSection}
			{instructionsSection}
			{pastAlliancesSection}
		</React.Fragment>
	);
};

export default AllianceIndex;
