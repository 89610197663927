import React, { useEffect, useState } from 'react';
import { SwitchTransition, Transition } from 'react-transition-group';
import SEO from '../components/seo';
import Reveal from '../components/Reveal';
import Link from '../components/Link';
import InfiniteScrollComponent from '../components/InfiniteScrollComponent';

import { useNavigate } from '@reach/router';
import scrollTo from 'magic-tricks/lib/scrollTo';
import cx from 'classnames';
import sanityImage from '../util/sanityImage';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';

export const TRANSITION_DURATION = 400;

const TRANSITION_STYLES = {
	default: {
		transition: `opacity ${TRANSITION_DURATION}ms ease`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
};

const ProjectList = ({ pageContext, location }) => {
	const {
		seo = {},
		featuredProjectsTitle,
		featuredProjects = [],
		listTitle,
		list = [],
		upcomingListTitle,
		upcomingList = [],
		pastListTitle,
		pastList = [],
	} = pageContext;

	const [currentFilter, setCurrentFilter] = useState(null);
	const [categories, setCategories] = useState([]);

	const navigate = useNavigate();
	const { search } = location;

	const featuredCategories = featuredProjects ? [...featuredProjects] : [];
	const featuredList = list ? [...list] : [];
	const featuredUpcomingList = upcomingList ? [...upcomingList] : [];
	const featuredPastList = pastList ? [...pastList] : [];

	const projectCategories = reduce(
		[
			...featuredCategories,
			...featuredList,
			...featuredUpcomingList,
			...featuredPastList,
		],
		(currentArray, item) => {
			if (item.linkType === 'alliance') {
				if (currentArray.includes('Alliance')) return currentArray;
				return [...currentArray, 'Alliance'];
			} else if (!currentArray.includes(item.type)) {
				return [...currentArray, item.type];
			} else {
				return currentArray;
			}
		},
		[]
	);

	useEffect(() => {
		setCategories(projectCategories);
	}, []);

	useEffect(() => {
		const filter = search.split('?type=')[1];
		filter && setCurrentFilter(decodeURI(filter));

		return () => {
			setCurrentFilter(null);
		};
	}, [search]);

	const metaTitle = seo?.metaTitle || 'Projects | Library Street Collective';
	const openGraphTitle =
		seo?.openGraphTitle || 'Projects | Library Street Collective';
	const twitterTitle =
		seo?.twitterTitle || 'Projects | Library Street Collective';

	const navSection = categories && categories.length > 0 && (
		<nav className="psy--md projects__navigation bb--black z10">
			<section className="df fw grid-container contained pb1 pb0--lg">
				<button
					className={cx(
						'sans--14 sans--18--lg button--filter mr2 mt1 mb1--lg',
						{
							active: currentFilter === null,
						}
					)}
					onClick={() => {
						navigate(`/projects`);
						setCurrentFilter(null);
						setTimeout(() => {
							scrollTo(0 - 90);
						}, 200);
					}}
				>
					All
				</button>
				{categories.map(cat => (
					<button
						className={cx(
							'sans--14 sans--18--lg button--filter mr2 mt1 mb1--lg',
							{
								active: currentFilter === cat,
							}
						)}
						onClick={() => {
							navigate(`?type=${cat}`);
							setCurrentFilter(cat);
							setTimeout(() => {
								scrollTo(0 - 90);
							}, 200);
						}}
					>
						{cat}
					</button>
				))}
			</section>
		</nav>
	);

	const unfilteredProjects = (
		<React.Fragment>
			{featuredProjects && featuredProjects.length > 0 && (
				<section className={cx('')}>
					<p className="sans--24 sans--36--lg">
						{featuredProjectsTitle || 'Featured Projects'}
					</p>

					<InfiniteScrollComponent className="mt2 mt5--md row df fw">
						{featuredProjects.map((item, index) => {
							const {
								_key,
								linkType,
								slug,
								title,
								artist,
								heroImage,
							} = item;

							return (
								<li
									className={cx('col c4--md', {
										mt0: index === 0,
										mt5: index > 0,
										'mt0--md': index < 3,
										'mt5--md mt10--lg': index > 2,
									})}
								>
									<Link
										key={_key}
										url={
											linkType === 'project'
												? `/projects/${slug}`
												: `/alliance/${slug}`
										}
										className="df fdc y"
									>
										<picture className="b--black image--tile">
											<source
												srcSet={sanityImage(heroImage, {
													w: 1200,
												})}
												media="(min-width: 600px)"
											/>
											<img
												alt={
													title ||
													artist ||
													'Library Street Collective'
												}
												className="db featured-link--image y"
												src={sanityImage(heroImage, {
													w: 1200,
												})}
											/>
										</picture>
										<aside className="mt2">
											<p className="sans--24 sans--36--md">
												{linkType === 'project' &&
													artist}
												{linkType === 'alliance' &&
													`Alliance: ${artist}`}
											</p>
											{linkType === 'project' && (
												<p className="sans--14 sans--18">
													{title}
												</p>
											)}
										</aside>
									</Link>
								</li>
							);
						})}
					</InfiniteScrollComponent>
				</section>
			)}

			{upcomingList && upcomingList.length > 0 && (
				<section
					className={cx('', {
						'mt5 mt10--md':
							featuredProjects && featuredProjects.length > 0,
					})}
				>
					<p className="sans--24 sans--36--lg">
						{upcomingListTitle || 'Upcoming Projects'}
					</p>
					<InfiniteScrollComponent className="mt2 mt5--md row df fw">
						{upcomingList.map((item, index) => {
							const {
								_key,
								linkType,
								slug,
								title,
								artist,
								heroImage,
							} = item;

							return (
								<li
									className={cx('col c4--md', {
										mt0: index === 0,
										mt5: index > 0,
										'mt0--md': index < 3,
										'mt5--md mt10--lg': index > 2,
									})}
								>
									<Link
										key={_key}
										url={
											linkType === 'project'
												? `/projects/${slug}`
												: `/alliance/${slug}`
										}
										className="df fdc y"
									>
										<picture className="b--black image--tile">
											<source
												srcSet={sanityImage(heroImage, {
													w: 1200,
												})}
												media="(min-width: 600px)"
											/>
											<img
												alt={
													title ||
													artist ||
													'Library Street Collective'
												}
												className="db featured-link--image y"
												src={sanityImage(heroImage, {
													w: 1200,
												})}
											/>
										</picture>
										<aside className="mt2">
											<p className="sans--24 sans--36--md">
												{linkType === 'project' &&
													artist}
												{linkType === 'alliance' &&
													`Alliance: ${artist}`}
											</p>
											{linkType === 'project' && (
												<p className="sans--14 sans--18">
													{title}
												</p>
											)}
										</aside>
									</Link>
								</li>
							);
						})}
					</InfiniteScrollComponent>
				</section>
			)}

			{list && list.length > 0 && (
				<section
					className={cx('', {
						'mt5 mt10--md': upcomingList && upcomingList.length > 0,
					})}
				>
					<p className="sans--24 sans--36--lg">
						{listTitle || 'Current Projects'}
					</p>
					<InfiniteScrollComponent className="mt2 mt5--md row df fw">
						{list.map((item, index) => {
							const {
								_key,
								linkType,
								slug,
								title,
								artist,
								heroImage,
							} = item;

							return (
								<li
									className={cx('col c4--md', {
										mt0: index === 0,
										mt5: index > 0,
										'mt0--md': index < 3,
										'mt5--md mt10--lg': index > 2,
									})}
								>
									<Link
										key={_key}
										url={
											linkType === 'project'
												? `/projects/${slug}`
												: `/alliance/${slug}`
										}
										className="df fdc y"
									>
										<picture className="b--black image--tile">
											<source
												srcSet={sanityImage(heroImage, {
													w: 1200,
												})}
												media="(min-width: 600px)"
											/>
											<img
												alt={
													title ||
													artist ||
													'Library Street Collective'
												}
												className="db featured-link--image y"
												src={sanityImage(heroImage, {
													w: 1200,
												})}
											/>
										</picture>
										<aside className="mt2">
											<p className="sans--24 sans--36--md">
												{linkType === 'project' &&
													artist}
												{linkType === 'alliance' &&
													`Alliance: ${artist}`}
											</p>
											{linkType === 'project' && (
												<p className="sans--14 sans--18">
													{title}
												</p>
											)}
										</aside>
									</Link>
								</li>
							);
						})}
					</InfiniteScrollComponent>
				</section>
			)}

			{pastList && pastList.length > 0 && (
				<section
					className={cx('', {
						'mt5 mt10--md': list && list.length > 0,
					})}
				>
					<p className="sans--24 sans--36--lg">
						{pastListTitle || 'Past Projects'}
					</p>
					<InfiniteScrollComponent className="mt2 mt5--md row df fw">
						{pastList.map((item, index) => {
							const {
								_key,
								linkType,
								slug,
								title,
								artist,
								heroImage,
							} = item;

							return (
								<li
									className={cx('col c4--md', {
										mt0: index === 0,
										mt5: index > 0,
										'mt0--md': index < 3,
										'mt5--md mt10--lg': index > 2,
									})}
								>
									<Link
										key={_key}
										url={
											linkType === 'project'
												? `/projects/${slug}`
												: `/alliance/${slug}`
										}
										className="df fdc y"
									>
										<picture className="b--black image--tile">
											<source
												srcSet={sanityImage(heroImage, {
													w: 1200,
												})}
												media="(min-width: 600px)"
											/>
											<img
												alt={
													title ||
													artist ||
													'Library Street Collective'
												}
												className="db featured-link--image y"
												src={sanityImage(heroImage, {
													w: 1200,
												})}
											/>
										</picture>
										<aside className="mt2">
											<p className="sans--24 sans--36--md">
												{linkType === 'project' &&
													artist}
												{linkType === 'alliance' &&
													`Alliance: ${artist}`}
											</p>
											{linkType === 'project' && (
												<p className="sans--14 sans--18">
													{title}
												</p>
											)}
										</aside>
									</Link>
								</li>
							);
						})}
					</InfiniteScrollComponent>
				</section>
			)}
		</React.Fragment>
	);

	const filteredProjectsList = (
		<React.Fragment>
			{featuredProjects &&
				filter(
					featuredProjects,
					item =>
						item.type === currentFilter ||
						item.linkType === currentFilter?.toLowerCase()
				).length > 0 && (
					<section className={cx('')}>
						<p className="sans--24 sans--36--lg">
							{featuredProjectsTitle || 'Featured Projects'}
						</p>
						<InfiniteScrollComponent className="mt2 mt5--md row df fw">
							{filter(
								featuredProjects,
								item =>
									item.type === currentFilter ||
									item.linkType ===
										currentFilter?.toLowerCase()
							).map(
								(
									{
										_key,
										slug,
										title,
										artist,
										heroImage,
										type,
										linkType,
									},
									index
								) => (
									<li
										className={cx('col c4--md', {
											mt0: index === 0,
											mt5: index > 0,
											'mt0--md': index < 3,
											'mt5--md mt10--lg': index > 2,
										})}
									>
										<Link
											key={_key}
											url={
												linkType === 'project'
													? `/projects/${slug}`
													: `/alliance/${slug}`
											}
											className="df fdc y"
										>
											<picture className="b--black image--tile">
												<source
													srcSet={sanityImage(
														heroImage,
														{
															w: 1200,
														}
													)}
													media="(min-width: 600px)"
												/>
												<img
													alt={
														title ||
														artist ||
														'Library Street Collective'
													}
													className="db featured-link--image y"
													src={sanityImage(
														heroImage,
														{
															w: 1200,
														}
													)}
												/>
											</picture>
											<aside className="mt2">
												<p className="sans--24 sans--36--md">
													{linkType === 'project' &&
														artist}
													{linkType === 'alliance' &&
														`Alliance: ${artist}`}
												</p>
												{linkType === 'project' && (
													<p className="sans--14 sans--18">
														{title}
													</p>
												)}
											</aside>
										</Link>
									</li>
								)
							)}
						</InfiniteScrollComponent>
					</section>
				)}

			{upcomingList &&
				filter(
					upcomingList,
					item =>
						item.type === currentFilter ||
						item.linkType === currentFilter?.toLowerCase()
				).length > 0 && (
					<section
						className={cx('', {
							'mt5 mt10--md':
								featuredProjects && featuredProjects.length > 0,
						})}
					>
						<p className="sans--24 sans--36--lg">
							{upcomingListTitle || 'Upcoming Projects'}
						</p>
						<InfiniteScrollComponent className="mt2 mt5--md row df fw">
							{filter(
								upcomingList,
								item =>
									item.type === currentFilter ||
									item.linkType ===
										currentFilter?.toLowerCase()
							).map(
								(
									{
										_key,
										slug,
										title,
										artist,
										heroImage,
										type,
										linkType,
									},
									index
								) => (
									<li
										className={cx('col c4--md', {
											mt0: index === 0,
											mt5: index > 0,
											'mt0--md': index < 3,
											'mt5--md mt10--lg': index > 2,
										})}
									>
										<Link
											key={_key}
											url={
												linkType === 'project'
													? `/projects/${slug}`
													: `/alliance/${slug}`
											}
											className="df fdc y"
										>
											<picture className="b--black image--tile">
												<source
													srcSet={sanityImage(
														heroImage,
														{
															w: 1200,
														}
													)}
													media="(min-width: 600px)"
												/>
												<img
													alt={
														title ||
														artist ||
														'Library Street Collective'
													}
													className="db featured-link--image y"
													src={sanityImage(
														heroImage,
														{
															w: 1200,
														}
													)}
												/>
											</picture>
											<aside className="mt2">
												<p className="sans--24 sans--36--md">
													{linkType === 'project' &&
														artist}
													{linkType === 'alliance' &&
														`Alliance: ${artist}`}
												</p>
												{linkType === 'project' && (
													<p className="sans--14 sans--18">
														{title}
													</p>
												)}
											</aside>
										</Link>
									</li>
								)
							)}
						</InfiniteScrollComponent>
					</section>
				)}

			{list &&
				filter(
					list,
					item =>
						item.type === currentFilter ||
						item.linkType === currentFilter?.toLowerCase()
				).length > 0 && (
					<section
						className={cx('', {
							'mt5 mt10--md':
								upcomingList && upcomingList.length > 0,
						})}
					>
						<p className="sans--24 sans--36--lg">
							{listTitle || 'Current Projects'}
						</p>
						<InfiniteScrollComponent className="mt2 mt5--md row df fw">
							{filter(
								list,
								item =>
									item.type === currentFilter ||
									item.linkType ===
										currentFilter?.toLowerCase()
							).map(
								(
									{
										_key,
										slug,
										title,
										artist,
										heroImage,
										type,
										linkType,
									},
									index
								) => (
									<li
										className={cx('col c4--md', {
											mt0: index === 0,
											mt5: index > 0,
											'mt0--md': index < 3,
											'mt5--md mt10--lg': index > 2,
										})}
									>
										<Link
											key={_key}
											url={
												linkType === 'project'
													? `/projects/${slug}`
													: `/alliance/${slug}`
											}
											className="df fdc y"
										>
											<picture className="b--black image--tile">
												<source
													srcSet={sanityImage(
														heroImage,
														{
															w: 1200,
														}
													)}
													media="(min-width: 600px)"
												/>
												<img
													alt={
														title ||
														artist ||
														'Library Street Collective'
													}
													className="db featured-link--image y"
													src={sanityImage(
														heroImage,
														{
															w: 1200,
														}
													)}
												/>
											</picture>
											<aside className="mt2">
												<p className="sans--24 sans--36--md">
													{linkType === 'project' &&
														artist}
													{linkType === 'alliance' &&
														`Alliance: ${artist}`}
												</p>
												{linkType === 'project' && (
													<p className="sans--14 sans--18">
														{title}
													</p>
												)}
											</aside>
										</Link>
									</li>
								)
							)}
						</InfiniteScrollComponent>
					</section>
				)}

			{pastList &&
				filter(
					pastList,
					item =>
						item.type === currentFilter ||
						item.linkType === currentFilter?.toLowerCase()
				).length > 0 && (
					<section
						className={cx('', {
							'mt5 mt10--md': list && list.length > 0,
						})}
					>
						<p className="sans--24 sans--36--lg">
							{pastListTitle || 'Past Projects'}
						</p>
						<InfiniteScrollComponent className="mt2 mt5--md row df fw">
							{filter(
								pastList,
								item =>
									item.type === currentFilter ||
									item.linkType ===
										currentFilter?.toLowerCase()
							).map(
								(
									{
										_key,
										slug,
										title,
										artist,
										heroImage,
										type,
										linkType,
									},
									index
								) => (
									<li
										className={cx('col c4--md', {
											mt0: index === 0,
											mt5: index > 0,
											'mt0--md': index < 3,
											'mt5--md mt10--lg': index > 2,
										})}
									>
										<Link
											key={_key}
											url={
												linkType === 'project'
													? `/projects/${slug}`
													: `/alliance/${slug}`
											}
											className="df fdc y"
										>
											<picture className="b--black image--tile">
												<source
													srcSet={sanityImage(
														heroImage,
														{
															w: 1200,
														}
													)}
													media="(min-width: 600px)"
												/>
												<img
													alt={
														title ||
														artist ||
														'Library Street Collective'
													}
													className="db featured-link--image y"
													src={sanityImage(
														heroImage,
														{
															w: 1200,
														}
													)}
												/>
											</picture>
											<aside className="mt2">
												<p className="sans--24 sans--36--md">
													{linkType === 'project' &&
														artist}
													{linkType === 'alliance' &&
														`Alliance: ${artist}`}
												</p>
												{linkType === 'project' && (
													<p className="sans--14 sans--18">
														{title}
													</p>
												)}
											</aside>
										</Link>
									</li>
								)
							)}
						</InfiniteScrollComponent>
					</section>
				)}
		</React.Fragment>
	);

	return (
		<React.Fragment>
			<SEO
				metaTitle={metaTitle}
				metaDescription={seo?.metaDescription}
				metaKeywords={seo?.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo?.openGraphDescription}
				openGraphImage={seo?.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo?.twitterDescription}
				twitterImage={seo?.twitterImage}
				pathname={location.pathname}
			/>
			<h1 className="hidden-title">Projects</h1>
			<Reveal>
				<section className="reveal__slide reveal__delay--1">
					{navSection}
					<div className="grid-container contained mt5 mt10--md mb5 mb10--md mb20--lg">
						<SwitchTransition>
							<Transition
								key={currentFilter}
								mountOnEnter
								unmountOnExit
								appear
								timeout={TRANSITION_DURATION}
							>
								{status => (
									<section
										className="projects__list"
										style={{
											...TRANSITION_STYLES.default,
											...TRANSITION_STYLES[status],
										}}
									>
										{currentFilter === null &&
											unfilteredProjects}
										{currentFilter && filteredProjectsList}
									</section>
								)}
							</Transition>
						</SwitchTransition>
					</div>
				</section>
			</Reveal>
		</React.Fragment>
	);
};

export default ProjectList;
