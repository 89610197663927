import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import Reveal from '../components/Reveal';
import SEO from '../components/seo';
import Link from '../components/Link';
import cx from 'classnames';
import sanityImage from '../util/sanityImage';

const ArtistList = ({ pageContext, location }) => {
  	const {
		artistsHeaderTitle,
		artistsHeaderDesc,
		featuredArtistsTitle,
		featuredArtists = [],
		listTitle,
		artistsListDesc,
		list = [],
		seo = {},
	} = pageContext;

	const metaTitle = seo?.metaTitle || 'Artists | Library Street Collective';
	const openGraphTitle =
		seo?.openGraphTitle || 'Artists | Library Street Collective';
	const twitterTitle =
		seo?.twitterTitle || 'Artists | Library Street Collective';

	const featuredArtistsSection = featuredArtists &&
		featuredArtists.length > 0 && (
			<Reveal>
				<section className="reveal__slide reveal__delay--1 grid-container contained mt5 mt10--md mb5 mb10--md mb20--lg">

					{artistsHeaderTitle && (
					<div class="contained--left mb5 mb10--md mb20--lg artist-headerr">
						<p className="sans--24 sans--48--md sans--64--lg">{artistsHeaderTitle}</p>
						{artistsHeaderDesc && (
						<BlockContent blocks={artistsHeaderDesc} serializers={richText} />
						)}
					</div>
					)}

					{featuredArtistsTitle && (
					<h2 className="sans--24 sans--24--md sans--32--lg text-center">
						{featuredArtistsTitle}
					</h2>
					)}
					<section className="row df fw mt2 mt5--lg">
						{featuredArtists.map((item, index) => {
							const {
								_type,
								thumbnail,
								customTitle,
								title,
								hero,
								heroImage,
								image,
								slug,
							} = item;
							const picture =
								thumbnail || hero || image || heroImage;

							let location;

							switch (_type) {
								case 'featuredArtist':
									location = `/artists/${slug}`;
									break;
								case 'featuredProject':
									location = `/projects/${slug}`;
									break;
								case 'featuredExhibition':
									location = `/exhibitions/${slug}`;
									break;
								case 'featuredSite':
									location = `/exhibitions/site-${slug}`;
									break;
								case 'featuredAnatomy':
									location = `/exhibitions/anatomy-${slug}`;
									break;
								default:
									break;
							}

							return (
								<article
									className={cx(`col c6--md`, {
										mt0: index === 0,
										'mt5 mt0--md': index === 1,
										'mt5 mt10--md': index > 1,
									})}
								>
									<Link
										key={item._key}
										className="df fdc jcb y"
										url={location}
									>
										<picture className="b--black image--tile">
											<source
												srcSet={sanityImage(
													picture.url,
													{
														w: 1400,
													}
												)}
												media="(min-width: 1000px)"
											/>
											<source
												srcSet={sanityImage(
													picture.url,
													{
														w: 1200,
													}
												)}
												media="(min-width: 600px)"
											/>
											<img
												alt={
													picture.artist ||
													'Library Street Collective'
												}
												className="db featured-link--image y"
												src={sanityImage(
													thumbnail.url,
													{ w: 1200 }
												)}
											/>
										</picture>
										<p className="sans--18 sans--36--md mt2">
											{customTitle && customTitle}
											{!customTitle &&
												_type == 'featuredAnatomy' &&
												`Anatomy: ${title}`}
											{!customTitle &&
												_type == 'featuredSite' &&
												`SITE: ${title}`}
											{!customTitle &&
												_type == 'featuredExhibition' &&
												title}
											{!customTitle &&
												_type == 'featuredArtist' &&
												title}
											{!customTitle &&
												_type == 'featuredProject' &&
												title}
										</p>
									</Link>
								</article>
							);
						})}
					</section>
				</section>
			</Reveal>
		);

	const listSection = list && list.length > 0 && (
		<Reveal className="grid-container contained mt5 mt10--md mb5 mb10--md mb20--lg artist-headerrr">
			{listTitle && (
			<h2 className="sans--24 sans--24--md sans--32--lg text-center">
				{listTitle}
			</h2>
			)}
			{artistsListDesc && (
			<BlockContent blocks={artistsListDesc} serializers={richText} />
			)}
			<section className="row df fw mt2 mt5--lg">
				{list.map((item, index) => {
					const {
						_type,
						thumbnail,
						title,
						customTitle,
						slug,
						hero,
						heroImage,
						image,
						artist,
					} = item;
					const picture = thumbnail || hero || image || heroImage;

					let location;

					switch (_type) {
						case 'featuredArtist':
							location = `/artists/${slug}`;
							break;
						case 'featuredProject':
							location = `/projects/${slug}`;
							break;
						case 'featuredExhibition':
							location = `/exhibitions/${slug}`;
							break;
						case 'featuredSite':
							location = `/exhibitions/site-${slug}`;
							break;
						case 'featuredAnatomy':
							location = `/exhibitions/anatomy-${slug}`;
							break;
						default:
							break;
					}

					return (
						<article
							className={cx(`col c6 c3--md`, {
								mt0: index === 0,
								'mt5 mt0--md': index > 1,
								'mt5 mt10--md': index > 3,
							})}
						>
							<Link
								key={item._key}
								className="df fdc jcb y"
								url={location}
							>
								{picture && (
									<picture className="b--black image--tile">
										<source
											srcSet={sanityImage(picture.url, {
												w: 1400,
											})}
											media="(min-width: 1000px)"
										/>
										<source
											srcSet={sanityImage(picture.url, {
												w: 1200,
											})}
											media="(min-width: 600px)"
										/>
										<img
											alt={
												title ||
												artist ||
												'Library Street Collective'
											}
											className="db featured-link--image y"
											src={sanityImage(picture.url, {
												w: 1200,
											})}
										/>
									</picture>
								)}
								<p className="mt1 mt2--md sans--18 sans--24--md">
									{customTitle && customTitle}
									{!customTitle &&
										_type == 'featuredAnatomy' &&
										`Anatomy: ${title}`}
									{!customTitle &&
										_type == 'featuredSite' &&
										`SITE: ${title}`}
									{!customTitle &&
										_type == 'featuredExhibition' &&
										title}
									{!customTitle &&
										_type == 'featuredArtist' &&
										title}
									{!customTitle &&
										_type == 'featuredProject' &&
										title}
								</p>
							</Link>
						</article>
					);
				})}
			</section>
		</Reveal>
	);

	return (
		<React.Fragment>
			<SEO
				metaTitle={metaTitle}
				metaDescription={seo?.metaDescription}
				metaKeywords={seo?.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo?.openGraphDescription}
				openGraphImage={seo?.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo?.twitterDescription}
				twitterImage={seo?.twitterImage}
				pathname={location.pathname}
			/>
			<h1 className="hidden-title">Artists</h1>
			{featuredArtistsSection}
			{listSection}
		</React.Fragment>
	);
};

export default ArtistList;
