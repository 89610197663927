import React from 'react';
import scroll from '../util/scrollHelper';

export default ({ list = [] }) => {
	const half_length = Math.ceil(list.length / 2);

	const left = list.slice(0, half_length);
	const right = list.slice(half_length, list.length);

	return (
		<div className="mt5 mt10--md mb5 mb10--md mb20--lg">
			<p className="sans--24 sans--36--md sans--48--lg mb5 mb10--md">
				SITE Artists
			</p>
			<div className="row">
				<ul className="col c6 ">
					{left.map(artist => (
						<li className="" key={artist._key}>
							<a
								onClick={e => {
									e.preventDefault();
									scroll(`#artist_section_${artist._key}`);
								}}
								className="sans--18 sans--24--md sans--36--lg"
								href={`#artist_section_${artist._key}`}
							>
								<span className="link--underline-reverse fit db">
									{artist.title}
								</span>
							</a>
						</li>
					))}
				</ul>
				<ul className="col c6 ">
					{right.map(artist => (
						<li className="" key={artist._key}>
							<a
								onClick={e => {
									e.preventDefault();
									scroll(`#artist_section_${artist._key}`);
								}}
								className="sans--18 sans--24--md sans--36--lg"
								href={`#artist_section_${artist._key}`}
							>
								<span className="link--underline-reverse fit db">
									{artist.title}
								</span>
							</a>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};
