import React, { useState, useEffect, useContext } from 'react';
import Context from '../context/Context';
import SEO from '../components/seo';
import Reveal from '../components/Reveal';
import Carousel from '../components/Carousel';

import centsToPrice from 'magic-tricks/lib/centsToPrice';
import cx from 'classnames';
import sanityImage from '../util/sanityImage';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';

const Shop = ({ pageContext, location }) => {
	const {
		seo = {},
		title,
		vendor,
		cents,
		productId,
		variantId,
		images = [],
		description,
		details = [],
	} = pageContext;

	const { fetchProduct, addVariantToCart } = useContext(Context);
	const [available, setAvailable] = useState(true);

	const [currentSlide, setCurrentSlide] = React.useState(0);
	const [sliderRef, slider] = useKeenSlider({
		initial: 0,
		loop: true,
		slideChanged(s) {
			setCurrentSlide(s.details().relativeSlide);
		},
	});

	useEffect(() => {
		const updateProduct = async () => {
			const product = await fetchProduct(productId);
			setAvailable(product.availableForSale);
		};
		updateProduct();
	}, []);

	const metaTitle = seo?.metaTitle || 'Library Street Collective';
	const openGraphTitle = seo?.openGraphTitle || 'Library Street Collective';
	const twitterTitle = seo?.twitterTitle || 'Library Street Collective';

	return (
		<React.Fragment>
			<SEO
				metaTitle={metaTitle}
				metaDescription={seo?.metaDescription}
				metaKeywords={seo?.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo?.openGraphDescription}
				openGraphImage={seo?.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo?.twitterDescription}
				twitterImage={seo?.twitterImage}
				pathname={location.pathname}
			/>
			<Reveal>
				<section className="reveal__slide reveal__delay--1 grid-container contained mt5 mt10--lg mb5 mb10--md mb20--lg pr">
					<div className="row">
						{images && images.length > 0 && (
							<figure className="col c5--lg psy--lg top pt10--lg">
								{images.length > 1 ? (
									<Carousel list={images} />
								) : (
									<picture>
										<source
											srcSet={sanityImage(images[0].url, {
												w: 2000,
											})}
											media="(min-width: 1200px)"
										/>
										<source
											srcSet={sanityImage(images[0].url, {
												w: 1200,
											})}
											media="(min-width: 1000px)"
										/>
										<source
											srcSet={sanityImage(images[0].url, {
												w: 1000,
											})}
											media="(min-width: 800px)"
										/>
										<source
											srcSet={sanityImage(images[0].url, {
												w: 800,
											})}
											media="(min-width: 600px)"
										/>
										<img
											alt={
												images[0].alt ||
												title ||
												'Library Street Collective'
											}
											className="db x b--black"
											srcSet={sanityImage(images[0].url, {
												w: 600,
											})}
										/>
									</picture>
								)}
							</figure>
						)}
						<div className="col c1--lg show--lg pt5--md pt10--lg" />
						<aside className="col c6--lg pt5 pt10--lg">
							<div className="df jcb">
								<h1 className="sans--18 sans--24--md sans--36--lg">
									{title}
									{vendor && (
										<span className="db sans--14 sans--18--md sans--24--lg">
											{vendor}
										</span>
									)}
								</h1>
								<p className="sans--14 sans--18--md sans--24--lg">
									{centsToPrice(cents)}
								</p>
							</div>
							{description && (
								<div className="mt5 mt10--md sans--14 sans--18--md rich-text">
									<BlockContent
										blocks={description}
										serializers={richText}
									/>
								</div>
							)}
							{details && details.length > 0 && (
								<div className="mt5">
									<p className="sans--14 sans--18--md sans--24--lg">
										Details
									</p>
									{details.map((item, index) => {
										const { _key, title, value } = item;

										return (
											<div
												key={_key}
												className={cx(
													'df sans--14 sans--18--lg',
													{
														mt2: index === 0,
														mt1: index > 0,
													}
												)}
											>
												<p className="col c4 c3--md">
													{title}
												</p>
												<p>{value}</p>
											</div>
										);
									})}
								</div>
							)}
							<button
								disabled={!available}
								onClick={() => {
									if (!available) return;
									addVariantToCart(variantId, 1);
								}}
								className={cx('mt5 x db tile--large', {
									disabled: !available,
								})}
							>
								<span className="sans--14 sans--18--md sans--24--lg">
									{available
										? 'Add to Cart'
										: 'Currently Unavailable'}
								</span>
							</button>
						</aside>
					</div>
				</section>
			</Reveal>
		</React.Fragment>
	);
};

export default Shop;
