import React, { useContext } from 'react';
import Reveal from './Reveal';
import VideoPlayer from './VideoPlayer';
import ComponentList from './ComponentList';
import Context from '../context/Context';

import cx from 'classnames';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import useHover from '../util/useHover';

export default ({
	_key,
	cover,
	coverVideo,
	content = [],
	description,
	introText,
	portrait,
	portraitCaption,
	title,
}) => {
	const [hoverRef, isHovered] = useHover();
	const { setLightBoxOpen, setFeaturedImage } = useContext(Context);

	return (
		<Reveal>
			<section
				className="reveal__slide reveal__delay--1 mt5 mt10--md mb5 mb10--md mb20--lg"
				id={`#artist_section_${_key}`}
			>
				{cover && (
					<picture
						className="db x b--black artwork--full-container"
						ref={hoverRef}
					>
						<source
							srcSet={`${cover.url}?w=2000&auto=format&q=100`}
							media="(min-width: 1200px)"
						/>
						<source
							srcSet={`${cover.url}?w=1200&auto=format&q=100`}
							media="(min-width: 1000px)"
						/>
						<source
							srcSet={`${cover.url}?w=800&auto=format`}
							media="(min-width: 600px)"
						/>
						<img
							alt={`Artwork by ${title}`}
							className={cx(
								'db x b--black artwork--full pointer',
								{
									hovered: isHovered,
								}
							)}
							onClick={() => {
								setFeaturedImage({
									artwork: cover,
								});
								setTimeout(() => {
									setLightBoxOpen(true);
								}, 100);
							}}
							src={`${cover.url}?w=600&auto=format`}
						/>
					</picture>
				)}

				{coverVideo && <VideoPlayer {...coverVideo} />}

				{introText && (
					<div className="row align--center mt5 mt10--lg mb5 mb10--lg">
						<div className="col c10--md c9--lg c7--xl">
							<div className="sans--18 sans--24--md line-break rich-text ">
								<BlockContent
									blocks={introText}
									serializers={richText}
								/>
							</div>
						</div>
					</div>
				)}

				<p className="sans--24 sans--36--md sans--48--lg mt5">
					{title}
				</p>

				<div className="mt2 mb5 mb10--md mb20--lg">
					<div className="row">
						<figure className="col c5--lg psy--lg top pt10--lg">
							<picture>
								<source
									srcSet={`${portrait.url}?w=1200&auto=format&q=100`}
									media="(min-width: 1000px)"
								/>
								<source
									srcSet={`${portrait.url}?w=800&auto=format`}
									media="(min-width: 600px)"
								/>
								<img
									alt={
										title || 'Image courtesy of the artist'
									}
									className="db x b--black pointer"
									onClick={() => {
										setFeaturedImage({
											artwork: portrait,
											title: portraitCaption,
										});
										setTimeout(() => {
											setLightBoxOpen(true);
										}, 100);
									}}
									src={`${portrait.url}?w=600&auto=format`}
								/>
							</picture>
							{portraitCaption && (
								<figcaption className="mt2 sans--14 sans--18--md">
									{portraitCaption}
								</figcaption>
							)}
						</figure>
						<div className="col c1--lg show--lg pt10--lg" />
						<div className="col c6--lg pt5 pt10--lg">
							<div className=" sans--18 sans--24--md line-break rich-text ">
								<BlockContent
									blocks={description}
									serializers={richText}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="mt2 mt5--lg">
					<ComponentList components={content} />
				</div>
			</section>
		</Reveal>
	);
};
